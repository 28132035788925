import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="Certificate of Employers' Liability Insurance">
    <Hero title="Certificate of Employers' Liability Insurance" />
    <Container>
      <EmberCard>
        <EmberCardSection>
          <StaticImage
            src="./certificate-of-insurance-2023-24.png"
            alt="Certificate of Employers' Liability Insurance"
          />
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
